import React from 'react';
// import sections
import TextPage from '../components/sections/TextPage';
//import Intro from '../components/sections/Intro';
//import FeaturesTiles from '../components/sections/FeaturesTiles';
//import FeaturesSplit from '../components/sections/FeaturesSplit';
//import Testimonial from '../components/sections/Testimonial';
//import Cta from '../components/sections/Cta';

const Impressum = () => {

  return (
    <>
      <TextPage className="illustration-section-01" />
    </>
  );
}

export default Impressum;