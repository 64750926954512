// import React, { useState } from 'react';
import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
//import ButtonGroup from '../elements/ButtonGroup';
//import Button from '../elements/Button';
//import Image from '../elements/Image';
//import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Intro = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  // const [videoModalActive, setVideomodalactive] = useState(false);

  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // }

  // const closeModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(false);
  // }   

  const outerClasses = classNames(
    'hero section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'intro-inner ',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" id="about">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className="container-m">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Das Büro <span className="text-color-primary">KONSTRUKT</span> – gegründet als Personengesellschaft bürgerlichen Rechts im Jahr 2022 – bestehend aus den beiden Gesellschaftern André Schade und Nermin Cerić – verbindet seither die Themenfelder Entwässerungs- und Freiraumplanung. „Ihr Vorhaben ist unser Handwerk!“ ist nicht nur eine Floskel, sondern ein Versprechen. Wir, das KONSTRUKT, legen besonderen Wert darauf, Sie als Endkunden und Partner individuell, fachspezifisch und mit dem gewissen Blick auf eine kostenbewusste Umsetzung zu beraten. 
                </p>
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Beide <span className="text-color-primary">KONSTRUKT</span>*eure – André Schade und Nermin Cerić - gründeten Ihre berufliche Laufbahn auf einer Berufsausbildung zum Bauzeichner. Darauf aufbauend bildeten sie sich über verschiedenste Wege, größtenteils berufsbegleitend, stetig weiter. Heute blicken beide auf technische Berufsweiterbildungs- und Studienabschlüsse sowie umfangreiche Berufserfahrung im Bereich Projektleitung und Bauplanung zurück. 
              </p>
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Den Grundstein für die Zusammenarbeit im <span className="text-color-primary">KONSTRUKT</span> ermöglichte eine gemeinsame berufliche Station bei einem kommunalen Arbeitgeber in Nordrhein-Westfalen. Dort entwickelte sich aufgrund der gleichen Motivation und Zielsetzungen aus Kollegialität eine Partnerschaft – das heutige <span className="text-color-primary">KONSTRUKT</span>! 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Intro.propTypes = propTypes;
Intro.defaultProps = defaultProps;

export default Intro;