// import React, { useState } from 'react';
import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
//import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const TextPage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {


  const outerClasses = classNames(
    'hero section ',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm" id="welcome">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1>Impressum</h1>
            <h3>Angaben gemäß § 5 TMG</h3>
            <p>Konstrukt - Schade, André - Cerić, Nermin GbR <br/>
                Jakob-Kneip-Straße 156 <br/> 
                40595 Düsseldorf <br/>
            </p>
            <h3> Vertreten durch: </h3>
            <p> Schade, André  & Cerić, Nermin GbR </p> 

            <h3>Kontakt</h3>
            <p>
              Telefon: 0172/4593379 <br/> 
              E-Mail: info@konstrukt-ing.de
            </p>

            <h3>Umsatzsteuer-ID</h3>
            <p> Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE 35 60 96 77 5</p>

            <h3>Berufsbezeichnung und berufsrechtliche Regelungen </h3>
            <p> Berufsbezeichnung: Ingenieur <br/> 
                Verliehen in: Deutschland
            </p>
            <h3> Angaben zur Berufshaftpflichtversicherung </h3> 
            <p>
              <b>Name und Sitz des Versicherers:</b> <br/>
              VHV Allgemeine Versicherung AG Ottoplatz 6 <br/>
              50679 Köln <br/>
              <b>Geltungsraum der Versicherung:</b> <br/>
              Deutschland
            </p>
            <h3>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h3>
            <p> Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. </p>

          </div>
          {/*<div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div>*/}
          {/*<Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" />*/}
        </div>
      </div>
    </section>
  );
}

TextPage.propTypes = propTypes;
TextPage.defaultProps = defaultProps;

export default TextPage;