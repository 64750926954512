import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

 // const sectionHeader = {
 //   title: 'Über das KONSTRUKT'
 // };

const sectionHeaderLeistung = {
    title: 'UNSERE LEISTUNGSFELDER IM ÜBERBLICK'
  };
  
  //paragraph: 'Das KONSTRUKT ist durch die unterschiedlichen erklommenen Etappen, wie die Arbeit beim Ingenieurbüro (privat), bei der Hochschule (Lehre) sowie bei der Kommune (öffentlich) bestens aufgestellt für Ihre Anliegen. Zudem verstehen wir uns als Dienstleister für den Dienstleister (Architekt/Ingenieur).'

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" id="leistungen">
        <div className={innerClasses}>
          
          {/*<div className="section-image-content">
            
          </div>*/}
          <div className={splitClasses}>

            

            <SectionHeader data={sectionHeaderLeistung} className="center-content" />

            {<div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  SIEDLUNGSWASSER&shy;WIRTSCHAFT
                  </h3>
                <p className="m-0">
                  Sie sind Bauherr oder Architekt und sind auf der Suche nach einem fachkundigen und zuverlässigen Partner für die Planung von Entwässerungsanlagen? Wir bieten Ihnen fachspezifische Beratungs- und Dienstleistungen im generellen Grundstücksentwässerung nach DIN 1986-100 sowie Kanalbau.
                  <br/>
                  Die Siedlungswasserwirtschaft befasst sich u. a. mit der Behandlung und Beseitigung von Niederschlagswasser sowie anfallendem Schmutzwasser. Insbesondere die Neu- und Umplanung von Entwässerungsanlagen erfordert intensive Abstimmungen mit den zuständigen Behörden. 
                  <br/>
                  Als Fachplaner für Grundstücksentwässerung nach DIN 1986-100 und Kanalbau unterstützen wir Sie bei der Planung sowie der Antragsstellung und Kommunikation mit genehmigenden Behörden. 

                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Entwaesserung.jpg')}
                  alt="Entwässerung"
                   />
              </div>
            </div>}

            {<div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  FREIRAUM
                  </h3>
                <p className="m-0">
                  Sie sind auf der Suche nach einem fachkundigen und zuverlässigen Partner für die Planung ihrer Freianlagen oder Verkehrsanlagen? Wir bieten Ihnen fachspezifische Beratungs- und Dienstleistungen rund um diese zusammenhängenden Themenfelder.
                  <br/>
                  Als Freiraum definiert, sind sämtliche nicht durch Gebäude bebaute Flächen. 
                  Die Freiraumplanung beschäftigt sich daher beispielsweise mit der Gestaltung und Gliederung von Straßen- und Verkehrsanlagen, Grün- und Parkanlagen, Freizeitanlagen für Spiel und Sport sowie klassischen Außenanlagen von Gebäuden. Dabei ist der Fokus neben reiner Funktionalität auch auf die Gestaltung und Ästhetik sowie Wirtschaftlichkeit zu legen. Den Grundstein für den Erfolg dieser Maßnahmen legt dabei eine detaillierte und zuverlässige Planung. 

                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Freianlagen.jpg')}
                  alt="Freianlagen"
                  width={200}
                  height={150} />
              </div>
            </div>}


            {<div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  TECHNISCHE ZEICHNUNGEN
                  </h3>
                <p className="m-0">
                  Sie haben bereits genaue Vorstellung von Ihrem Vorhaben und möchten dieses nun in Form einer technischen Zeichnung zur Präsentation beim Kunden, Antragsstellung bei Behörden, baulichen Umsetzung o. ä. visualisieren? Auf Basis Ihrer Anforderungen und Vorgaben konstruieren wir Ihr Projekt in Form von Lageplänen, Grundrissen, Ansichten, Schnitten sowie weiteren Detailplänen und geben ihm so ein Gesicht. 
                  <br/>
                  Wir bieten Ihnen fachspezifische Beratungs- und Dienstleistungen rund um das Thema CAD.

                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Technische_Zeichnungen.jpg')}
                  alt="Technische_Zeichnungen"
                  width={528}
                  height={396} />
              </div>
            </div>}
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;