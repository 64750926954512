import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
//import Input from '../elements/Input';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  //const innerClasses = classNames(
  //  'cta-inner section-inner',
  //  topDivider && 'has-top-divider',
  //  bottomDivider && 'has-bottom-divider',
  //  split && 'cta-split'
  //);  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" id="kontakt">
      <h2 className="center-content">Kontakt</h2>
          <p>Wir haben Ihr Interesse geweckt? Kontaktieren Sie uns unverbindlich über folgende Kontaktdaten</p>
          <p><span className="text-color-primary">KONSTRUKT</span><br/> Schade,Andre - Cerić, Nermin GbR</p>
          <p>André Schade: <a href="tel:+491724593379">0172/4593379</a></p>
          <p>Nermin Cerić: <a href="tel:+491622433650">0162/2433650</a></p>
          <p>E-Mail: <a target="_blank" rel="noopener noreferrer" href="mailto:info@konstrukt-ing.de">info@konstrukt-ing.de</a></p>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;